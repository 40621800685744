import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const InfoBlock = makeShortcode("InfoBlock");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "realise-love",
      "style": {
        "position": "relative"
      }
    }}>{`Realise Love`}<a parentName="h1" {...{
        "href": "#realise-love",
        "aria-label": "realise love permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <InfoBlock mdxType="InfoBlock">
      <p>{`“He speaks about reality, about its complexity and comprehensibility, but he defends us from the conceptual trap of wanting to find it an ultimate foundation.”`}</p>
    </InfoBlock>
    <p>{`In Kernel, we always return to `}<a parentName="p" {...{
        "href": "/conversation/reciprocity"
      }}>{`reciprocity`}</a>{`, repeating the `}<a parentName="p" {...{
        "href": "/learn/module-7/giving/#receiving-gracefully"
      }}>{`primacy of relationships`}</a>{`: “Layer 0 is the people”. Indeed, this entire section is intended to describe a philosophy of education that revolves around `}<a parentName="p" {...{
        "href": "/conversation/hospitality"
      }}>{`hosting`}</a>{` honest, humble and truthful conversations and relating to others in wholesome ways which can move any moment you share towards collective wisdom. It is about learning to live in and with love.`}</p>
    <p>{`This piece makes a radical claim. Relations are not just primary: they are the `}<em parentName="p">{`only`}</em>{` way in which reality becomes manifest. Understanding that relations are all we can describe about reality allows us to understand ourselves and others and the world in a more wholesome and clear-eyed way. Intuiting `}<em parentName="p">{`what`}</em>{` relates is how we are made ready for meaningful interaction; and how we make in each moment a full recognition of the living gift it is.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“The best description of reality that we have found is in terms of events that weave a web of interactions `}{`[...]`}{` Everything is what it is only with respect to something else.”`}</p>
    </blockquote>
    <p>{`We will base our enquiry on two texts: `}<a parentName="p" {...{
        "href": "https://www.goodreads.com/book/show/56098210-helgoland"
      }}><em parentName="a">{`Helgoland: The World of Quantum Theory`}</em></a>{` by Carlo Rovelli, and the `}<a parentName="p" {...{
        "href": "https://www.tsemrinpoche.com/download/Buddhism-Philosophy-and-Doctrine/en/Jay%20L.%20Garfield%20-%20The%20Fundamental%20Wisdom%20of%20the%20Middle%20Way%20-%20Nagarjuna%E2%80%99s%20Mulamadhyamakakarika.pdf"
      }}><em parentName="a">{`Mūlamadhyamakakārikā`}</em></a>{` (or `}<em parentName="p">{`Root Verses of the Middle Way`}</em>{`) by Nāgārjuna, translated by Jay L. Garfield.`}</p>
    <h2 {...{
      "id": "the-quest-questioned",
      "style": {
        "position": "relative"
      }
    }}>{`The Quest, Questioned`}<a parentName="h2" {...{
        "href": "#the-quest-questioned",
        "aria-label": "the quest questioned permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`In physics, we define a quantum as a discrete quantity of energy.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“The discovery of quanta changes `}<a parentName="p" {...{
          "href": "/learn/module-2/better-questions"
        }}>{`the terms of our questions`}</a>{` `}{`[such that]`}{` the gulf between ‘simple matter’ and the evanescent unravelling of our spirit appears to be a little less impassable.”`}</p>
    </blockquote>
    <p>{`To bridge this gulf between matter and spirit, we will travel to the heart of modern physics and look at the implications of quantum theory, not as a means of introducing complexity, but as a way of crafting better questions about what relations really are. In particular, the world as a web of interaction implies that there is no such thing as ‘simple matter’, and it is certainly not distinct from spirit: that `}<a parentName="p" {...{
        "href": "https://andytudhope.africa/soul/"
      }}>{`particular set of relations`}</a>{` which breathes purpose and meaning into any action we choose.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“What quantum theory describes, then, is `}<em parentName="p">{`the way in which one part of nature manifests itself to any other`}</em>{` `}{`[... A photon, a cat, a stone, a clock, a tree, a boy, a village, a rainbow, a planet, a cluster of galaxies]`}{` do not exist in splendid isolation. On the contrary, they do nothing but continuously act upon each other. To understand nature, we must focus on these interactions rather than on isolated objects.”`}</p>
    </blockquote>
    <p>{`Indeed, it seems that the very concept of an individual is just that - a concept. It has no ground in most modern science: be it `}<a parentName="p" {...{
        "href": "https://www.journals.uchicago.edu/doi/10.1086/668166"
      }}>{`biology`}</a>{` or `}<a parentName="p" {...{
        "href": "https://achrafkassioui.com/resources/Francisco%20Varela%20-%20Three%20Gestures%20of%20Becoming%20Aware.pdf"
      }}>{`cognitive science`}</a>{`. In the second link, Francisco Varela explicitly states that: `}</p>
    <blockquote>
      <p parentName="blockquote">{`"human beings `}{`[...]`}{` don't have a solid substance as individuals", because the subject "is not a stable, solid entity, since it is not within the head, nor is it just in language. It's in none of those dimensions. It's somehow in a figure of multiple levels of emergence, but it is always fragile `}{`[...]`}{` Life is constantly in this process of reaccommodation."`}</p>
    </blockquote>
    <p>{`Yet this concept has a strong pull on our perception of things given the cultures we are currently conditioned by. What we call 'ego', that part of our relating which seems to strive so hard to construct its own separation (in `}<a parentName="p" {...{
        "href": "https://www.azquotes.com/picture-quotes/quote-the-ships-hung-in-the-sky-in-much-the-same-way-that-bricks-don-t-douglas-adams-34-67-79.jpg"
      }}>{`much the same way`}</a>{` that any proof by contradiction works), does very much seem to exist. Rovelli turns to Ernst Mach (who exerted a great influence over Heisenberg and many others) to consider this strange set of relations.`}</p>
    <p>{`Mach once wrote “Das Ego ist unrettbar”; the Ego cannot be saved. However, you need not wage war on the ego to relate well: you simply need to go on noticing its paradoxes and letting them go by virtue of having the good humour and perspective to laugh at yourself and care for others who suffer the ill effects of less conscious contradictions. The noticing, and the humour, and the compassion make you response-able, perhaps even equanimous. It makes it possible to relate wisely, which Varela describes beautifully:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"A life of wisdom is to be constantly engaged in that letting go, and letting the fragility of the self manifest itself. When you are with somebody who really has that capacity to a full-blown level, it affects you. When we meet those kinds of people, it's clear, because the whole process is not individual, it's not private, and you enter into that kind of resonance. You relax — there's something very enjoyable about it. There's a joy in that kind of life; that's the good life, I'd say."`}</p>
    </blockquote>
    <p>{`Why do we relax joyfully into such experiences? Perhaps it is not that they "relate us to the real" in some supposedly objective sense, but because how we find ourselves relating `}<em parentName="p">{`is what is real`}</em>{`? Returning to Rovelli:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Individual objects `}<em parentName="p">{`are`}</em>{` the way in which they interact `}{`[and]`}{` reality is this web of interactions. Instead of seeing the physical world as a collection of objects with definite properties, quantum theory invites us to see the physical world as a net of relations.”`}</p>
    </blockquote>
    <h2 {...{
      "id": "the-observer-influenced",
      "style": {
        "position": "relative"
      }
    }}>{`The Observer, Influenced`}<a parentName="h2" {...{
        "href": "#the-observer-influenced",
        "aria-label": "the observer influenced permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`The way is empty,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`used, but not used up `}{`[...]`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Quiet,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`yes, and likely to endure `}{`[...]`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Empty yet structured,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`it moves, inexhaustibly giving.`}</em>{`  `}</p>
        <p>{`— `}<a parentName="p" {...{
            "href": "https://terebess.hu/english/tao/LeGuin.pdf"
          }}>{`Tao te Ching`}</a></p>
      </Box>
    </InfoBlock>
    <p>{`The world as a web of interactions implies no special ground from which to witness, no separate place from which one can observe. Indeed: `}</p>
    <blockquote>
      <p parentName="blockquote">{`“There is nothing special in the ‘observations’ introduced by Heisenberg: `}<em parentName="p">{`any`}</em>{` interaction between two physical objects can be seen as an observation. We must be able to `}<strong parentName="p">{`treat any object as an ‘observer’`}</strong>{`.”`}</p>
    </blockquote>
    <p>{`Rovelli is making a radical point: any object (not just human, not even just ‘conscious’) is an observer. The falling tree is felt in the whole world with which it relates. Forget any illusions of a privileged place and simply be a part of that which is present in each passing moment. Doing this is to do without doing, for it reflects the pattern already present in each and all.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“The discovery of quantum theory, I believe, is the discovery that the properties of any entity are nothing other than the way in which that entity influences others. It exists only through its interactions. `}<strong parentName="p">{`Quantum theory is the theory of how things influence each other`}</strong>{`. And this is the best description of nature that we have.”`}</p>
    </blockquote>
    <p>{`When we recognise that `}<a parentName="p" {...{
        "href": "/learn/module-0/trust/#access-ability"
      }}>{`all we are is how we influence each other`}</a>{`, the deep spring becomes clear. Moreover, such good drinking water does not flow to just one side only:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“The relational perspective allows `}{`[seemingly contradictory]`}{` `}<em parentName="p">{`things to be true`}</em>{`: each relates to interactions with respect to distinct observers: you and me. Is it possible that a fact might be real with respect to you and not real with respect to me? Quantum theory, I believe, is the discovery that the answer is yes. `}<em parentName="p">{`Facts that are real with respect to an object are not necessarily so with respect to another`}</em>{`.”`}</p>
    </blockquote>
    <p>{`The quote above refers to “Schrödinger’s cat”, a famous thought experiment from quantum mechanics in which we imagine a cat that has been trapped in a box with some sleeping potion. For the cat, the vial containing the potion has either been cracked or not, while for you - standing outside the box - the cat remains in a superposition.  We don’t need many universes or branching timelines to explain this: just the simple and profound realisation that facts that are real for you need not be real for me. `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/learn/module-0/play-of-pattern"
        }}>{`Both things can be true`}</a></strong>{`. After all, `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=-6BsiVyC1kM&t=1207s"
      }}>{`facts are just values`}</a>{`. Sharing them on a public ledger can be very helpful in terms of understanding more precisely what we agree about. It does not make them True, but it does help us relate more truthfully.`}</p>
    <h2 {...{
      "id": "the-context-bridged",
      "style": {
        "position": "relative"
      }
    }}>{`The Context, Bridged`}<a parentName="h2" {...{
        "href": "#the-context-bridged",
        "aria-label": "the context bridged permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`When the fully enlightened ones do not appear`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`And when the disciples have disappeared,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`The wisdom of the self-enlightened ones`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Will arise completely without a teacher`}</em>{`.`}</p>
        <p>{`— The `}<em parentName="p">{`Mūlamadhyamakakārikā`}</em>{`  `}</p>
      </Box>
    </InfoBlock>
    <p>{`We can more fully accept that my facts need not be your facts when we realise that there are no intrinsic properties in anything, no essential ground:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Properties do not reside in objects, they are bridges between objects. Objects are such only with respect to other objects, they are nodes where bridges meet. The world is a perspectival game, a play of mirrors that exist only as reflections of and in each other.”`}</p>
    </blockquote>
    <p>{`We understand from our study of `}<a parentName="p" {...{
        "href": "/build/regeneration"
      }}>{`applied poetics`}</a>{` that the bridge is also not an object, but a system of tensions. Words, too, work between the limits of what can be coherently said and the way such sayings point to what cannot be said without paradox, but can still be understood. As Owen Barfield once wrote:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Words are only themselves by being more than themselves. Perhaps the same is true of human beings.”`}</p>
    </blockquote>
    <p>{`This same understanding takes Rovelli from fundamental physics into the realms of politics and culture, discussing everything from a debate between Lenin and Bogdanov, to the influence of Ernst Mach on Russian politics and German physicists alike.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“This is the meaning of culture; an endless dialogue that enriches us by feeding on experiences, knowledge and, above all, `}<strong parentName="p"><a parentName="strong" {...{
            "href": "https://thebluebook.co.za/tangled-bank/"
          }}>{`exchanges`}</a></strong>{`.”`}</p>
    </blockquote>
    <p>{`In `}<a parentName="p" {...{
        "href": "https://www.esperanzaproject.com/2021/native-american-culture/the-blackfoot-wisdom-that-inspired-maslows-hierarchy/"
      }}>{`other words`}</a>{`, "Our lives are inextricably tied up with one another." This can be a daunting thought: where are we to find a thread to even begin working with if everything is bound to everything else? Well, as the above link demonstrates, "Indigenous communities offer us an example of what is possible when we embrace this reality."`}</p>
    <p>{`Taking such communities as our inspiration, dialogue in Kernel is done peer-to-peer, `}<a parentName="p" {...{
        "href": "https://www.yalom.com/gift-of-therapy-chapter-3"
      }}>{`without any clear-cut distinction`}</a>{` between `}<a parentName="p" {...{
        "href": "https://living.thebluebook.co.za/faith/transmission.html"
      }}>{`teacher or student`}</a>{`, and without any singular concept of “fact” or Truth. Sometimes we impart knowledge, sometimes we receive it. Sometimes both at once. Sometimes neither. In any of these cases, we are always learning. To bridge dualities, we focus not on absolutes, but on co-relations:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“The raw material out of which the world is built up is not of two sorts, one matter and the other mind; it is simply arranged in different patterns by its inter-relations: some arrangements may be called mental, while others may be called physical.” — Bertrand Russell`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`“The difference between the psychological and physical orders boils down to the difference between experience organised individually and experience organised socially.” — Aleksandr Bogdanov`}</p>
    </blockquote>
    <p>{`Different methods of organisation imply different contexts, and it is an appreciation of context which allows us to see that any object exists with its total environment, from which it is impossible to cleave it without voiding the properties of that object. This inseparability provides a foundation for fluid and responsive dialogue. Seeing the world as a web of interactions which continually constitute the context for how things appear`}</p>
    <blockquote>
      <p parentName="blockquote">{`“leaves us without a foothold, no place to stand. If matter with definite and univocal properties does not constitute the elementary substance of the world, and if the subject of our knowledge is a part of nature, what `}<em parentName="p">{`is`}</em>{` the world’s elementary substance?”`}</p>
    </blockquote>
    <p>{`Why should this question about essence matter in the context of dialogue? Well, if you think that the world has some fundamental ground, or some essential substance, then you inevitably think in hierarchies and seek conclusions. You think of levels of development, or consciousness, or attainment, or whatever. You might even assure yourself that these levels are only useful conceptual tools, not to be mistaken for truth. However, you still see the world as something to be progressed through, instead of as relationships to become entangled with. `}</p>
    <p>{`When, finally, you have the courage to drop your perception of progress and attainment, you become more ready to transform reciprocally via dialogic relating, because it ceases to be about showing or teaching the “right way”, and becomes simply a means of inviting others to genuine, messy, tangled, overlapping fellowship, right now in this present moment of eternity. `}</p>
    <p>{`As Rovelli says, “The separation of reality into levels is relative to our way of being in interaction with it.” This takes us right to `}<a parentName="p" {...{
        "href": "https://youtu.be/kffo3pxNO7c?t=48"
      }}>{`the root of the root, and the bud of the bud`}</a>{`…`}</p>
    <h2 {...{
      "id": "the-logic-lyrical",
      "style": {
        "position": "relative"
      }
    }}>{`The Logic, Lyrical`}<a parentName="h2" {...{
        "href": "#the-logic-lyrical",
        "aria-label": "the logic lyrical permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`Whatever is the essence of the Tathāgatha,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`That is the essence of the world.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`The Tathāgatha has no essence.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`The world is without essence.`}</em>{`  `}</p>
        <p>{`— The `}<em parentName="p">{`Mūlamadhyamakakārikā`}</em></p>
      </Box>
    </InfoBlock>
    <p>{`Rovelli references Nāgārjuna and his `}<em parentName="p">{`Root Verses of the Middle Way`}</em>{` as a means of illustrating that there simply isn’t any elementary substance. It is related all the way up and down: “i carry your heart(i carry it in my heart)”. In order to illustrate the heartful ways we may bare one another, we offer here a limited selection of a translated version of the root verses in a rearranged order. `}</p>
    <p>{`Relating always entangles us with and by the way the way is repeatedly shared across time.`}</p>
    <p>{`Nāgārjuna uses the word ‘emptiness’ to describe this, a convention best explained by his translator, Jay Garfield: “To say that the table is empty is simply to say that it `}<strong parentName="p">{`lacks essence`}</strong>{` and importantly `}<em parentName="p">{`not`}</em>{` to say that it is completely nonexistent.” We must each find our own way to face honestly the conventional reality of phenomena—`}<em parentName="p">{`things are just as they are`}</em>{`—while remembering that all of it lacks inherent existence. The whole is empty, now play your part. `}<a parentName="p" {...{
        "href": "https://andytudhope.africa/academy/communal-language/#love-this-sound"
      }}>{`Toni Morrison put it best`}</a>{`: “Know it, and go on out the yard.”`}</p>
    <p>{`To enter this empty opening, we can first look at the logic it employs, a form known as the tetralemma (itself related to more “`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=N_35T4pmjZM&t=2233s"
      }}>{`fringe intersectional content`}</a>{`” from Kernel fellows). Nāgārjuna negates everything, but in a beautiful way which is also applied to his own process. For instance, he points out that if a mover and motion were the same, agent and action would be identical. But if they were different, you could have a mover without motion and motion with a mover. He then writes:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`When neither in identity`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Nor in difference `}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Can they be established,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`How can these two be established at all?`}</em>{`  `}</p>
    </InfoBlock>
    <p>{`This same logic is applied to arising, to coming and going, birth and death, finite and infinite, and so on. In all cases, we can’t say they are the same. We can’t say they are different. We can’t say they are both the same and different. Nor can we say that they are neither the same nor different.`}</p>
    <p>{`Can we say anything at all then? If there is no essence, what is the point? Well, we can notice `}<em parentName="p">{`how it relates`}</em>{` and thereby recognise our kinship, this familiarity already beating your heart. It can’t be said without paradox; it can be understood:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`The arisen, the nonarisen, and that which is arising`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Do not arise in any way at all.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Thus they should be understood`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Just like the gone, the not-gone, and the going.`}</em>{`  `}</p>
    </InfoBlock>
    <p>{`This fourfold logical form leads to the radiant application of emptiness to emptiness itself:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`“Empty” should not be asserted,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`“Nonempty” should not be asserted.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Neither both nor neither should be asserted.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`They are only used nominally.`}</em></p>
        <p><em parentName="p">{`How can the tetralemma of permanent and impermanent, etc.,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Be true of the peaceful?`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`How can the tetralemma of finite, infinite, etc.,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Be true of the peaceful?`}</em></p>
      </Box>
    </InfoBlock>
    <p>{`As Rovelli says,“No metaphysics survives. Emptiness is empty.” He does not comment on `}<a parentName="p" {...{
        "href": "https://unifying.thebluebook.co.za/"
      }}>{`peacefulness`}</a>{`.`}</p>
    <h2 {...{
      "id": "the-opening-empty",
      "style": {
        "position": "relative"
      }
    }}>{`The Opening, Empty`}<a parentName="h2" {...{
        "href": "#the-opening-empty",
        "aria-label": "the opening empty permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`Like a dream, like an illusion,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Like a city of Gandharvas,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`So have arising, abiding,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`And ceasing been explained`}</em>{`.  `}</p>
    </InfoBlock>
    <p>{`Such are the conclusions of Nāgārjuna’s logical lyricism. Note the use of simile here though: it is only `}<strong parentName="p">{`like`}</strong>{` an illusion, and this is how things are “explained”. Remember, a fact to me need not be a fact to you, and an illusion is not something which doesn’t exist; it is something which is not what it appears to be. Nāgārjuna describes it thus:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`The pacification of all objectification`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`And the pacification of illusion:`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`No Dharma was taught by the Buddha`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`At any time, in any place, to any person.`}</em>{`  `}</p>
    </InfoBlock>
    <p>{`To notice this is profound freedom. This world through which we guide each other is like a dream in that it has no inherent existence. However, there are conventions, which call for particular kinds of response-ability. Of course, the `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=LwLP62fL83k&t=649s"
      }}>{`conventions may be transcended`}</a>{` at any moment. The question is “how?” My suggestion is that—`}<em parentName="p">{`because my fact need not be your fact`}</em>{`—we may transcend conventions by virtue of the facts we choose to share, which is why a shared, public ledger is such an interesting phenomena at almost any level of analysis.`}</p>
    <p>{`When you can both face conventional reality as it is, and not forget that it is empty, then:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`For such a one, assertions like “it exists” or “it does not exist”—`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Such conceptions will cease.`}</em>{`  `}</p>
        <p><em parentName="p">{`To say “it is” is to grasp for permanence,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`To say “it is not” is to adopt the view of nihilism.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Therefore a wise person`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Does not say “exists” or “does not exist”.`}</em>{`  `}</p>
        <p><em parentName="p">{`I do not think that`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Those who teach that the self`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Is the same as or different from the entities`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Understand the meaning of the doctrine.`}</em>{`  `}</p>
        <p><em parentName="p">{`So, when the victorious one abides, they`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Are neither said to be existent`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Nor said to be nonexistent.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Neither both nor neither are said.`}</em>{`  `}</p>
      </Box>
    </InfoBlock>
    <p>{`This strange and self-recursive loop leads us to the culmination of the root verses. Harken to the quality of those who relate truly:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`The victorious ones have said`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`That emptiness is the relinquishing of all views.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`For whomever emptiness is a view,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`That one will accomplish nothing.`}</em>{`  `}</p>
    </InfoBlock>
    <p>{`Once we have given back all our views, we can `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=hQeg3dd-XM0"
      }}>{`listen to the world`}</a>{` as it is and offer ourselves in genuine fellowship to one another, seeing as the obvious has been made obvious:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`There is not the slightest difference`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Between cyclic existence and nirvāna.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`There is not the slightest difference`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Between nirvāna and cyclic existence.`}</em>{`  `}</p>
    </InfoBlock>
    <h2 {...{
      "id": "the-totality-mirrored",
      "style": {
        "position": "relative"
      }
    }}>{`The Totality, Mirrored`}<a parentName="h2" {...{
        "href": "#the-totality-mirrored",
        "aria-label": "the totality mirrored permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`Whatever is the limit of nirvāna,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`That is the limit of cyclic existence.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`There is not even the slightest difference between them,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Or even the subtlest thing.`}</em>{`  `}</p>
    </InfoBlock>
    <p>{`In the beginning of this enquiry, we quoted Rovelli, saying that, “the gulf between ‘simple matter’ and the evanescent unravelling of our spirit appears to be a little less impassable” and we can return to him now to ground all this fourfold fanfare in the world of modern physics:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“Nāgārjuna has given us a formidable conceptual tool for thinking about the relationality of quanta: we can think of interdependence without autonomous essence entering the equation. In fact, interdependence `}<em parentName="p">{`requires`}</em>{` us to forget all about autonomous essences.”`}</p>
    </blockquote>
    <p>{`This does indeed bridge the `}<a parentName="p" {...{
        "href": "https://www.themarginalian.org/2017/03/09/atom-and-archetype-pauli-jung/"
      }}>{`physical and the psychological`}</a>{`, mind and matter, because:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“If the physical world is woven from the subtle interplay of images in mirrors reflected in other mirrors, without the metaphysical foundation of a material substance, perhaps it becomes easier to recognize ourselves as part of that whole.”`}</p>
    </blockquote>
    <p>{`This idea of an interplay of mirrors is an old one, and deeply useful to finish our exploration of what it means to transform ourselves reciprocally with an understanding of both conventional relations and emptiness.`}</p>
    <blockquote>
      <p parentName="blockquote">{`“What is the phenomenology of our consciousness if not the name that these processes assign to themselves in the game of mirrors of relevant information? `}{`[...]`}{` If we imagine the totality of things, we are imagining being `}<em parentName="p">{`outside`}</em>{` the universe. But there is no ‘outside’ to the totality of things `}{`[...]`}{` The world `}<em parentName="p">{`is`}</em>{` this reciprocal reflection of perspective.”`}</p>
    </blockquote>
    <p>{`Reciprocal reflection of perspective is the mark of a welcoming and `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=Pw2vxMRxF5o&t=4543s"
      }}>{`wise host`}</a>{`. The way you relate to each other and the manner in which you reflect your perspective defines what properties become manifest in any moment. If you can forget your privileged place and simply take part with that which is present in each passing moment, you can understand (`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=uBc5twqL0fo"
      }}>{`partly`}</a>{`):`}</p>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><em parentName="p">{`To follow the Way`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`is not to need fulfilment.`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Unfulfilled, one may live on`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`needing no renewal.`}</em>{`  `}</p>
        <p>{`— `}<a parentName="p" {...{
            "href": "https://terebess.hu/english/tao/LeGuin.pdf"
          }}>{`Tao te Ching`}</a></p>
      </Box>
    </InfoBlock>
    <p>{`How we might let go the need to progress to fulfillment is a story worth telling and listening to, infinitely. As Teju Ravilochan said `}<a parentName="p" {...{
        "href": "https://www.esperanzaproject.com/2021/native-american-culture/the-blackfoot-wisdom-that-inspired-maslows-hierarchy/"
      }}>{`in this post`}</a>{`, "It’s a story that leaves no one without family: a story in which we begin by offering each other belonging, and continue by teaching our descendents how we lived: `}<strong parentName="p"><em parentName="strong">{`together`}</em></strong>{`."`}</p>
    <p>{`The world bewilders this wheeling mind, calling us ever onward into the undiscovered `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=3FCo-xQj2Lc"
      }}>{`ocean of truth`}</a>{`:`}</p>
    <InfoBlock mdxType="InfoBlock">
      <p><em parentName="p">{`I prostrate to Gautama`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Who through compassion`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Taught the true doctrine,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Which leads to the relinquishment of all views`}</em>{`.  `}</p>
    </InfoBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      